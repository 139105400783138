
<template>
  <div class="h-full flex">
    <!-- Narrow sidebar -->
    <div class="hidden w-28 h-screen fixed bg-gradient-to-b from-green-700 to-green-700 overflow-y-auto md:block">
      <div class="w-full py-6 flex flex-col items-center">
        <div class="flex-shrink-0 flex items-center">
          <img class="h-8 w-auto" :src="require('@/assets/images/logo-sm-white.png')" alt="Workflow" />
        </div>
        <div class="flex-1 mt-6 w-full px-2 space-y-1">
          <div v-for="item in sidebarNavigation" :key="item.name">
            <button v-if="getNavItemsPermission(item)" :class="[item.name === currentNavItem?.name ? 'border-2 border-white text-white' : 'text-indigo-100 hover:bg-green-500 hover:text-white', 'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium']" :aria-current="item.name === currentNavItem?.name ? 'page' : undefined" @click="item.action()">
              <component :is="item.icon" :class="[item.name === currentNavItem?.name ? 'text-white' : 'text-white group-hover:text-white', 'h-6 w-6']" aria-hidden="true" />
              <span class="mt-2 text-center">{{ item.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog as="div" class="md:hidden" @close="toggleMobileMenu(false)">
        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <div class="relative max-w-xs w-full bg-green-700 pt-5 pb-4 flex-1 flex flex-col">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-1 right-0 -mr-14 p-1">
                  <button type="button" class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white" @click="toggleMobileMenu(false)">
                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    <span class="sr-only">Close sidebar</span>
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-shrink-0 px-4 flex items-center">
                <img class="h-8 w-auto" :src="require('@/assets/images/logo-sm-white.png')" alt="Workflow" />
              </div>
              <div class="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                <nav class="h-full flex flex-col">
                  <div class="space-y-1">
                    <button
                      v-for="item in sidebarNavigation" :key="item.name"
                      class="w-full"
                      :class="[item.name === currentNavItem?.name ? 'bg-green-900 text-white' : 'text-indigo-100 hover:bg-green-900 hover:text-white', 'group py-2 px-3 rounded-md flex items-center text-sm font-medium']"
                      :aria-current="item.name === currentNavItem?.name ? 'page' : undefined"
                      @click="item.action()"
                    >
                      <component :is="item.icon" :class="[item.name === currentNavItem?.name ? 'text-white' : 'text-indigo-300 group-hover:text-white', 'mr-3 h-6 w-6']" aria-hidden="true" />
                      <span>{{ item.name }}</span>
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- Content area -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <header class="md:ml-28">
        <div class="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
          <button type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 md:hidden" @click="toggleMobileMenu(true)">
            <span class="sr-only">Open sidebar</span>
            <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div class="flex-1 flex justify-between px-4 sm:px-6">
            <div class="flex-1 flex">
              <div class="w-full flex md:ml-0" action="#" method="GET">
                <label for="search-field" class="sr-only">Search all files</label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                  <input name="search-field" v-model="searchField" id="search-field" class="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:ring-cyan-500 focus:placeholder-gray-400" placeholder="Search by short id" type="search" />
                </div>
              </div>
            </div>
            <div class="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
              <Listbox as="div" v-model="selected">
                <div class="mt-1 relative">
                  <ListboxButton class="bg-white relative h-full w-full pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span class="block truncate">{{ selected.name }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      <ListboxOption as="template" v-for="docType in docTypes" :key="docType.id" :value="docType" v-slot="{ active, selected }">
                        <li :class="[active ? 'text-white bg-green-700' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                          <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ docType.name }}
                          </span>

                          <span v-if="selected" :class="[active ? 'text-white' : 'text-green-700', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
            </Listbox>     
            <button class="bg-green-700 hover:bg-green-500 text-white font-bold py-2 px-4 rounded" @click="documentSearch"><SearchIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" /></button>  
              <!-- Profile dropdown -->
              <Menu as="div" class="relative">
                <div>
                  <MenuButton class="bg-white rounded-full border-white border-solid border-2 hover:border-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                    <span class="sr-only">Open user menu</span>
                    <avatar-ui v-if="username" size="sm" :username="username" />
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                      <button class="w-full text-left" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']" @click="item.action()">{{ item.name }}</button>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
      </header>
      <!-- Main content -->
      <div class="flex-1 flex items-stretch overflow-hidden">
        <main class="flex-1 md:ml-28 overflow-y-auto">
          <!-- Primary column -->
          <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
            <!-- Your content -->
            <slot></slot>
          </section>
        </main>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { clearStorage } from '@/storageSave'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Menu, MenuItems, MenuItem, MenuButton } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import {
  CheckCircleIcon,
  ClipboardListIcon,
  CollectionIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  MenuAlt2Icon,
  UserRemoveIcon,
  PencilIcon,
  SearchIcon,
  XIcon,
} from '@heroicons/vue/outline'
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import AvatarUi from '@/components/avatarUi.vue'


const signOut = inject('signOut');

const profile = computed(() => store.getters.getProfile);

// Data
const store = useStore();
const router = useRouter();
const mobileMenuOpen = ref(false);
const sidebarNavigation = ref([
  { name: 'Home', icon: HomeIcon, roles: ['all'], action: () => navigate('/') },
  { name: 'Advanced Search', icon: SearchIcon, roles: ['RESOURCING', 'ADMIN', 'H2R'], action: () => navigate('/search') },
  { name: 'Resourcing', icon: ClipboardListIcon, roles: ['RESOURCING', 'ADMIN'], action: () => navigate('/resourcing') },
  { name: 'All Requests', icon: CollectionIcon, roles: ['H2R', 'ADMIN'], action: () => navigate('/all-documents') },
  { name: 'Your Requests', icon: DocumentDuplicateIcon, roles: ['all'], action: () => navigate('/all-my-documents') },
  { name: 'Assigned Approvals', icon: CheckCircleIcon, roles: ['all'], action: () => navigate('/action-documents') },
  { name: 'Create Amendment', icon: PencilIcon, roles: ['all'], action: () => {
    clearStorage('amendsFormData');
    navigate('/create-amend');
  }},
  { name: 'Create Leaver', icon: UserRemoveIcon, roles: ['all'], action: () => {
    clearStorage('leaverForm');
    store.dispatch('clearAllLeaverFormData');
    navigate('/create-leaver/new-leaver');
  }}
]);

const userNavigationItems = ref([
  { name: 'Profile', action: () => navigate('/profile') },
  { name: 'Sign out', action: () => signOut() }
]);

const userNavigation = computed(() => {
  let navItems = userNavigationItems.value
  if (profile.value.H2R || profile.value.H2R) {
    navItems.unshift({
      name: 'Admin panel',
      action: () => navigate('/admin')
    });
  }
  return navItems;
});

const username = computed(() => {
  const titleArr = profile.value.Title?.split(' ');
  if (titleArr?.length) {
    const first = titleArr[1];
    const last = titleArr[0];
    return `${first[0]} ${last[0]}`
  } else {
    return ''
  }
});

const getNavItemsPermission = (item) => {
  if (item.roles.includes('all')) return true;
  if (profile.value.H2R && item.roles.includes('H2R')) return true;
  if (profile.value.RESOURCING && item.roles.includes('RESOURCING')) return true;
  if (profile.value.ADMIN && item.roles.includes('ADMIN')) return true;
  return false;
}

// computed properties
const currentNavItem = computed(() => sidebarNavigation.value.find((item) => 
  item.name === currentRoute.value.name
));

const currentRoute = computed(() => router.currentRoute.value);

// Methods
const navigate = (path) => router.push(path);
const toggleMobileMenu = (value) => mobileMenuOpen.value = value;

const searchField = ref()
const docTypes = [
  { id: 1, name: 'STARTER' },
  { id: 2, name: 'LEAVER' },
  { id: 3, name: 'AMEND' },
  { id: 4, name: 'OFFER' }
]

const selected = ref(docTypes[0])

const documentSearch = async () => router.push(`/search-results/${selected.value.name}/${searchField.value}`);

</script>