<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Contract details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div v-if="configItems?.CONTRACTS" class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div v-html="approvedContractType"></div>
          <div v-html="approvedContractLevel"></div>
          <div v-html="approvedContractName"></div>
          <div v-html="approvedPension"></div>
          <div v-html="approvedLifeCover"></div>
          <div v-html="approvedPrivateMedicalInsurance"></div>
          <div v-html="approvedCar"></div>
          <div v-html="approvedBonus"></div>
          <div v-html="approvedOvertime"></div>
          <div v-html="approvedAnnualLeave"></div>
          <div v-html="approvedSickness"></div>
          <div v-html="approvedProbation"></div>
          <div v-html="approvedNotice"></div>
          <div>
            <label for="minSalary" class="block text-sm font-medium text-gray-700">
              What is the minimum proposed salary or hourly rate? *
            </label>
            <div class="flex">
              <input
              id="minSalary"
              name="minSalary"
              type="text"
              :value="formData?.minimumPayRate"
              :disabled="disableForm"
              class="
                grow
                block
                w-full
                focus:ring-indigo-500 focus:border-indigo-500
                min-w-0
                rounded-md
                sm:text-sm
                border-gray-300
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              "
            />
            <select
              id="minSalaryCurrency"
              name="minSalaryCurrency"
              :value="formData?.minimumPayRateCurrency"
              :disabled="disableForm"
              class="
                ml-2
                max-w-lg
                block
                focus:ring-indigo-500 focus:border-indigo-500
                shadow-sm
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              "
              >
              <option>GBP</option>
              <option>USD</option>
              <option>EUR</option>
            </select>
            </div>
            
          </div>
          
          <div>
            <label for="maxSalary" class="block text-sm font-medium text-gray-700">
              What is the maximum proposed salary or hourly rate? *
            </label>
            <div class="flex">
              <input
                id="maxSalary"
                name="maxSalary"
                type="text"
                :value="formData?.maximumPayRate"
                :disabled="disableForm"

                class="
                  grow
                  block
                  w-full
                  focus:ring-indigo-500 focus:border-indigo-500
                  min-w-0
                  rounded-md
                  sm:text-sm
                  border-gray-300
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                "
              />
            <select
              id="maxSalaryCurrency"
              name="maxSalaryCurrency"
              :value="formData?.maximumPayRateCurrency"
              :disabled="disableForm"
              class="
                ml-2
                max-w-lg
                block
                focus:ring-indigo-500 focus:border-indigo-500
                shadow-sm
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              "
              >
              <option>GBP</option>
              <option>USD</option>
              <option>EUR</option>
            </select>
            </div>
            
          </div>
          <div>
            <div v-html="additionalOvertimeInfo"></div>
            <p class="text-red-500 text-bold">
              * Overtime must be stated within this box. 'Standard' should not be noted. This can delay the processing of the contact.
            </p>
          </div>
          <div v-html="additionalAllowances"></div>
          <div v-html="payrollFrequency"></div>
          <div v-html="salaryReviewMonth"></div>
        </div>
        <p class="text-red-500 text-bold">* Employees in job levels 1-4 should be assigned to the One HR System (online appraisal process).</p>
        <p class="text-red-500 text-bold">* Employees in job levels 5-7 will be assigned to the paper based appraisals process unless you select One HR in the drop down above.</p>
        <!-- End Form -->
      </div>
      <!-- Save button -->
    <!-- End Save button -->
    </div>
  </form>
</template>

<script setup>
import { Input, Textarea, Dropdown } from '@/form';
import { defineProps } from 'vue';

const props = defineProps(['formData', 'disableForm', 'configItems']);

const approvedContractType = Input('Contract Type:', 'approvedContractType', 'text', props.disableForm, props.formData.contractType);
const approvedContractLevel = Input('Contract Level:', 'approvedContractLevel', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).ContractLevel);
const approvedContractName = Input('Approved Contract Name:', 'approvedContractName', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).ContractName);
const approvedPension = Input('Approved Pension:', 'approvedPension', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).PensionScheme);
const approvedLifeCover = Input('Approved Life Cover:', 'approvedLifeCover', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).LifeCover);
const approvedPrivateMedicalInsurance = Input('Approved Private Medical Insurance:', 'approvedPrivateMedicalInsurance', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).PMI);
const approvedCar = Input('Approved Car:', 'approvedCar', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).Car);
const approvedBonus = Input('Approved Bonus %:', 'approvedBonus', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).Bonus);
const approvedOvertime = Input('Approved Overtime:', 'approvedOvertime', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).OvertimeEligibility);
const approvedAnnualLeave = Input('Approved Annual Leave Entitlement (Per Annum):', 'approvedAnnualLeave', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).Holidays);
const approvedSickness = Input('Approved Sickness:', 'approvedSickness', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).Sickness);
const approvedProbation = Input('Approved Probation Period:', 'approvedProbation', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).ProbationPeriod);
const approvedNotice = Input('Approved Notice Period:', 'approvedNotice', 'text', props.disableForm, props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).NoticePeriod);

const additionalOvertimeInfo = Textarea(
  'Please provide overtime rates and when they will apply (e.g. if a specific rate is only applicable at weekends). Also use this space for any specific payments due that are not captured in the Additional Allowances sectionbelow. *',
  'additionalOvertimeInfo',
  props.disableForm,
  props.configItems.CONTRACTS.find((c) => c.ContractType?.trim() === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).OvertimeNote
);
const additionalAllowances = Input(
  'Are there any additional allowances associated with this role?',
  'additionalAllowances',
  'text',
  props.disableForm,
  props.formData.additionalAllowances
);
const payrollFrequency = Dropdown(
  'What is the payroll frequency for this role?',
  'payrollFrequency',
  ['Weekly', 'Fortnightly', 'Monthly'],
  props.disableForm,
  props.formData.payrollFrequency
)
const salaryReviewMonth = Input(
  'In which month will the annual salary review take place? *',
  'salaryReviewMonth',
  'text',
  props.disableForm,
  props.configItems.CONTRACTS.find((c) => c.ContractTypev === props.formData.contractType?.trim()).Items.find((item) => item.ContractName === props.formData.contractLevel).ASRReviewDate
);
</script>
