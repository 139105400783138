<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Contract Details
        </h3>
        <div>
          <div class="grid grid-cols-3 gap-6">
            <div class="col-span-6">
              <label
                for="contractType"
                class="block text-sm font-medium text-gray-700"
                >Contract Type *</label
              >
              <select
                :value="formData.contractType"
                name="contractType"
                id="contract-type-input"
                autocomplete="name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                :class="{
                  'bg-gray-200 border-none cursor-not-allowed':
                    showApprovalSteps,
                  'error-border': v$.formData.contractType.$error,
                  'border-red-500': v$.formData.contractType.$error,
                }"
                :disabled="showApprovalSteps"
                @change="handleInputChange"
                @blur="v$.formData.contractType.$touch()"
              >
                <template v-if="showApprovalSteps">
                  <option
                    v-for="type in configData.CONTRACTS"
                    :key="type.ID"
                    :value="type.ContractType"
                  >
                    {{ type.ContractType }}
                  </option>
                </template>
                <template v-else>
                  <option
                    v-for="type in activeContracts"
                    :key="type.ID"
                    :value="type.ContractType"
                  >
                    {{ type.ContractType }}
                  </option>
                </template>
              </select>
              <!-- error message -->
              <span
                v-if="v$.formData.contractType.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.contractType.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="contractLevel"
                class="block text-sm font-medium text-gray-700"
                >Contract Level *</label
              >
              <select
                :value="formData.contractLevel"
                name="contractLevel"
                id="contract-level-input"
                autocomplete="name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                :class="{
                  'bg-gray-200 border-none cursor-not-allowed':
                    showApprovalSteps,
                  'error-border': v$.formData.contractLevel.$error,
                  'border-red-500': v$.formData.contractLevel.$error,
                }"
                :disabled="showApprovalSteps"
                @change="handleInputChange"
                @blur="v$.formData.contractLevel.$touch()"
              >
                <template v-if="showApprovalSteps">
                  <option
                    v-for="cl in contractLevels"
                    :key="cl.Contract"
                    :value="cl.ContractName"
                  >
                    {{ cl.ContractName }}
                  </option>
                </template>
                <template v-else>
                  <option
                    v-for="cl in activeContractLevels"
                    :key="cl.Contract"
                    :value="cl.ContractName"
                  >
                    {{ cl.ContractName }}
                  </option>
                </template>
              </select>
              <!-- error message -->
              <span
                v-if="v$.formData.contractLevel.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.contractLevel.$errors[0].$message }}
              </span>
              <p class="mt-2 block text-sm font-medium text-red-500 italic">
                * Confirmation and approval of the contract level is subject to
                change by the HR Approver.
              </p>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-6 mt-4">
            <div v-if="currentContractLevel" class="grid col-span-6">
              <p class="mt-4">
                <span class="text-blue-700 font-bold">Pension: </span
                >{{ currentContractLevel.PensionScheme }}
              </p>
              <p class="mt-4">
                <span class="text-blue-700 font-bold">Life Cover: </span
                >{{ currentContractLevel.LifeCover }}
              </p>
              <p class="mt-4">
                <span class="text-blue-700 font-bold"
                  >Private Medical Insurance:
                </span>
                {{ currentContractLevel.PMI }}
              </p>
              <p class="mt-4">
                <span class="text-blue-700 font-bold"> Car: </span
                >{{ currentContractLevel.Car }}<br />
                <span v-if="carAllowance" class="italic text-sm">
                  A car allowance can be offered if preferred or if the contract
                  is &lt; 12 months in duration
                </span>
              </p>
              <p class="mt-4">
                <span class="text-blue-700 font-bold">Bonus %: </span
                >{{ currentContractLevel.Bonus }}
              </p>
              <p class="mt-4">
                <span class="text-blue-700 font-bold">Overtime: </span>
                {{ currentContractLevel.OvertimeEligibility }}<br />
                <span
                  v-if="currentContractLevel.OvertimeNote"
                  class="italic text-sm"
                >
                  {{ currentContractLevel.OvertimeNote }}
                </span>
              </p>
              <p class="mt-4">
                <span class="text-blue-700 font-bold"
                  >Annual Leave Entitlement (Per Annum): </span
                >{{ currentContractLevel.Holidays }}<br />
              </p>
              <p class="mt-4">
                <span class="text-blue-700 font-bold">Sickness: </span
                >{{ currentContractLevel.Sickness }}
              </p>
              <p class="mt-4">
                <span class="text-blue-700 font-bold">Probation Period: </span
                >{{ currentContractLevel.ProbationPeriod }}
              </p>
              <p class="mt-4">
                <span class="text-blue-700 font-bold">Notice Period: </span
                >{{ currentContractLevel.NoticePeriod }}
              </p>
            </div>
          </div>

          <div
            v-if="businessCar"
            class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <fieldset class="col-span-6">
              <legend class="text-base font-medium text-gray-900">
                Please select your business need car requirement? *
              </legend>
              <span
                v-if="v$.formData.businessVehicle.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.businessVehicle.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        id="business-vehicle-car"
                        name="businessVehicle"
                        type="radio"
                        value="Car"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        :class="{
                          'bg-gray-200 border-none cursor-not-allowed':
                            showApprovalSteps,
                        }"
                        :disabled="showApprovalSteps"
                        :checked="formData.businessVehicle === 'Car'"
                        @change="handleRadioChange"
                        @blur="v$.formData.businessVehicle.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="businessVehicle"
                        class="font-medium text-gray-700"
                        >Car is required</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        id="business-vehicle-van"
                        name="businessVehicle"
                        type="radio"
                        value="Van"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        :class="{
                          'bg-gray-200 border-none cursor-not-allowed':
                            showApprovalSteps,
                        }"
                        :disabled="showApprovalSteps"
                        :checked="formData.businessVehicle === 'Van'"
                        @change="handleRadioChange"
                        @blur="v$.formData.businessVehicle.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="businessVehicle"
                        class="font-medium text-gray-700"
                        >Van is required</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        id="business-vehicle-neither"
                        name="businessVehicle"
                        type="radio"
                        value="Neither"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        :class="{
                          'bg-gray-200 border-none cursor-not-allowed':
                            showApprovalSteps,
                        }"
                        :disabled="showApprovalSteps"
                        :checked="formData.businessVehicle === 'Neither'"
                        @change="handleRadioChange"
                        @blur="v$.formData.businessVehicle.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="businessVehicle"
                        class="font-medium text-gray-700"
                        >Neither is required</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <template v-if="bonusType">
            <div
              class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
            />
            <label
              for="bonusType"
              class="text-base font-medium text-gray-900 block"
              >Please confirm the bonus type *</label
            >
            <select
              :value="formData.bonusType"
              name="bonusType"
              id="bonusType"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              @change="handleInputChange"
            >
              <option v-for="bt in bonusTypes" :key="bt" :value="bt">
                {{ bt }}
              </option>
            </select>
          </template>

          <div
            class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <fieldset class="col-span-6">
              <legend class="text-base font-medium text-gray-900">
                What is the proposed salary basis? *
              </legend>
              <span
                v-if="v$.formData.salaryBasis.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.salaryBasis.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        id="radio-basis-per-annum"
                        name="salaryBasis"
                        type="radio"
                        value="Per Annum"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        :class="{
                          'bg-gray-200 border-none cursor-not-allowed':
                            showApprovalSteps,
                        }"
                        :disabled="showApprovalSteps"
                        :checked="formData.salaryBasis === 'Per Annum'"
                        @change="handleRadioChange"
                        @blur="v$.formData.salaryBasis.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="salaryBasis" class="font-medium text-gray-700"
                        >Per Annum</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        id="radio-basis-per-hour"
                        name="salaryBasis"
                        type="radio"
                        value="Per Hour"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        :class="{
                          'bg-gray-200 border-none cursor-not-allowed':
                            showApprovalSteps,
                        }"
                        :disabled="showApprovalSteps"
                        :checked="formData.salaryBasis === 'Per Hour'"
                        @change="handleRadioChange"
                        @blur="v$.formData.salaryBasis.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="salaryBasis" class="font-medium text-gray-700"
                        >Per Hour</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div
            class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <div class="col-span-6 flex flex-col">
              <label
                for="minimumPayRate"
                class="block text-sm font-medium text-gray-700"
                >What is the minimum proposed salary or hourly rate? *</label
              >
              <div class="flex">
                <input
                  :value="formData.minimumPayRate"
                  type="number"
                  min="0"
                  name="minimumPayRate"
                  id="minimum-pay-rate-input"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                    'error-border': v$.formData.minimumPayRate.$error,
                    'border-red-500': v$.formData.minimumPayRate.$error,
                  }"
                  :disabled="showApprovalSteps"
                  @input="handleInputChange"
                  @blur="v$.formData.minimumPayRate.$touch()"
                />
                <select
                  :value="formData.minimumPayRateCurrency"
                  name="minimumPayRateCurrency"
                  class="mt-1 ml-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                  @change="handleInputChange"
                >
                  <option value="GBP" selected>GBP</option>
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                </select>
              </div>
              <!-- error message -->
              <span
                v-if="v$.formData.minimumPayRate.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.minimumPayRate.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div
            class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <div class="col-span-6 flex flex-col">
              <label
                for="maximumPayRate"
                class="block text-sm font-medium text-gray-700"
                >What is the maximum proposed salary or hourly rate? *</label
              >
              <div class="flex">
                <input
                  :value="formData.maximumPayRate"
                  type="number"
                  min="0"
                  name="maximumPayRate"
                  id="maximum-pay-rate-input"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                    'error-border': v$.formData.maximumPayRate.$error,
                    'border-red-500': v$.formData.maximumPayRate.$error,
                  }"
                  :disabled="showApprovalSteps"
                  @input="handleInputChange"
                  @blur="v$.formData.maximumPayRate.$touch()"
                />
                <select
                  :value="formData.maximumPayRateCurrency"
                  name="maximumPayRateCurrency"
                  class="mt-1 ml-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                  @change="handleInputChange"
                >
                  <option value="GBP" selected>GBP</option>
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                </select>
              </div>
              <!-- error message -->
              <span
                v-if="v$.formData.maximumPayRate.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.maximumPayRate.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div
            class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <fieldset class="col-span-6">
              <legend class="text-base font-medium text-gray-900">
                Are there any additional allowances associated with this role? *
              </legend>
              <span
                v-if="v$.formData.additionalAllowances.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.additionalAllowances.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        id="radio-additional-allowances-yes"
                        name="additionalAllowances"
                        type="radio"
                        value="Yes"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        :class="{
                          'bg-gray-200 border-none cursor-not-allowed':
                            showApprovalSteps,
                        }"
                        :disabled="showApprovalSteps"
                        :checked="formData.additionalAllowances === 'Yes'"
                        @change="handleRadioChange"
                        @blur="v$.formData.additionalAllowances.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="additionalAllowances"
                        class="font-medium text-gray-700"
                        >Yes</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        id="radio-additional-allowances-no"
                        name="additionalAllowances"
                        type="radio"
                        value="No"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        :class="{
                          'bg-gray-200 border-none cursor-not-allowed':
                            showApprovalSteps,
                        }"
                        :disabled="showApprovalSteps"
                        :checked="formData.additionalAllowances === 'No'"
                        @change="handleRadioChange"
                        @blur="v$.formData.additionalAllowances.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="additionalAllowances"
                        class="font-medium text-gray-700"
                        >No</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div
            class="mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
            v-if="model.additionalAllowances === 'Yes'"
          >
            <div
              class="p-2 m-4 border border-gray-200 rounded"
              v-for="(aa, index) in model.additionalAllowancesInformation"
              :key="aa"
            >
              <div class="col-span-6">
                <div class="flex justify-between">
                  <label
                    for="specify"
                    class="block text-sm font-medium text-gray-700"
                    >Please Specify *</label
                  >
                  <XIcon
                    v-if="index !== 0"
                    class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer"
                    @click.prevent="removeAllowance(index)"
                  />
                </div>
                <select
                  id="aspecify"
                  name="additionalAllowancesInformation"
                  autocomplete="specify"
                  :value="aa.additionalAllowancesInformation"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  @change="handleAllowanceChange($event, index)"
                >
                  <option selected="selected" disabled="true" value="selected">
                    Please select
                  </option>
                  <option value="Allowance">Allowance</option>
                  <option value="First Aid">First Aid</option>
                  <option value="Fixed Monthly Unsocial Allowance">
                    Fixed Monthly Unsocial Allowance
                  </option>
                  <option value="Flexable Allowance">Flexable Allowance</option>
                  <option value="Honorarium (NTC">Honorarium (NTC)</option>
                  <option value="London Allowance">London Allowance</option>
                  <option value="Night Allowance">Night Allowance</option>
                  <option value="On Call / Call Out">On Call / Call Out</option>
                  <option value="Shift Allowance">Shift Allowance</option>
                  <option value="Site Supplement">Site Supplement</option>
                  <option value="Standby">Standby</option>
                  <option value="Travel Allowance">Travel Allowance</option>
                  <option value="Weekend Allowance">Weekend Allowance</option>
                </select>
              </div>
              <div class="col-span-6 mt-2">
                <div class="flex">
                  <div class="w-full">
                    <label
                      for="additional-allowance-title"
                      class="block text-sm font-medium text-gray-700"
                      >Specify Value (per month)</label
                    >
                    <input
                      :value="aa.additionalAllowanceAmount"
                      type="number"
                      min="0"
                      name="additionalAllowanceAmount"
                      id="additional-allowance-amount"
                      autocomplete="name"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      @input="handleAllowanceChange($event, index)"
                    />
                  </div>
                  <div class="ml-2">
                    <label
                      for="additional-allowance-title"
                      class="block text-sm font-medium text-gray-700"
                      >Currency:</label
                    >
                    <select
                      id="aspecify"
                      name="additionalAllowancesCurrency"
                      autocomplete="specify"
                      :value="aa.additionalAllowancesCurrency"
                      class="mt-1 w-24 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      @change="handleAllowanceChange($event, index)"
                    >
                      <option selected value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button
                class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                @click.prevent="addAllowance"
              >
                Add allowance +
              </button>
            </div>
          </div>

          <div
            class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <fieldset class="col-span-6">
              <legend class="text-base font-medium text-gray-900">
                What is the payroll frequency for this role? *
              </legend>
              <span
                v-if="v$.formData.payrollFrequency.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.payrollFrequency.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        id="radio-payroll-frequency-monthly"
                        name="payrollFrequency"
                        type="radio"
                        value="Monthly"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        :class="{
                          'bg-gray-200 border-none cursor-not-allowed':
                            showApprovalSteps,
                        }"
                        :disabled="showApprovalSteps"
                        :checked="formData.payrollFrequency === 'Monthly'"
                        @change="handleRadioChange"
                        @blur="v$.formData.payrollFrequency.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="payroll-frequency-monthly"
                        class="font-medium text-gray-700"
                        >Monthly</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div
        v-if="!showApprovalSteps"
        class="px-4 py-3 bg-gray-50 text-right sm:px-6"
      >
        <button
          class="mr-2 bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          @click.prevent="handleResetForm"
        >
          Reset Form
        </button>
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400"
          @click="handleSave"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { XIcon } from "@heroicons/vue/outline";

export default {
  name: "FormSix",
  props: ["formData", "configData", "showApprovalSteps"],
  components: { XIcon },
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData,
      bonusTypes: [
        "Embedded Support",
        "Support Functions",
        "Profit Centre Management",
        "Project Manager",
        "Sales Manager",
      ],
    };
  },
  methods: {
    handleSave(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-form", { name: "formSix", formData: this.formData });
      } else {
        this.store.dispatch("setMessage", {
          type: "error",
          title: "ERROR!",
          message: "Please fill out all the required fields",
        });
      }
    },
    handleInputChange(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit("update:formData", {
        formName: "formSix",
        formData: this.model,
      });
    },
    handleAllowanceChange(event, index) {
      const { name, value } = event.currentTarget;
      this.model.additionalAllowancesInformation[index][name] = value;
      this.$emit("update:formData", {
        formName: "formSix",
        formData: this.model,
      });
    },
    handleRadioChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.model[name] = value;
      this.$emit("update:formData", {
        formName: "formSix",
        formData: this.model,
      });
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit("update:reset", "formSix");
    },
    addAllowance() {
      this.model.additionalAllowancesInformation.push({
        additionalAllowancesTitle: "",
        additionalAllowancesInformation: "",
        additionalAllowancesCurrency: "GBP",
      });
      this.$emit("update:formData", {
        formName: "formSix",
        formData: this.model,
      });
    },
    removeAllowance(index) {
      this.model.additionalAllowancesInformation.splice(index, 1);
      this.$emit("update:formData", {
        formName: "formSix",
        formData: this.model,
      });
    },
  },
  computed: {
    activeContracts() {
      return this.configData.CONTRACTS.filter((contract) => contract.LKStatus);
    },
    activeContractLevels() {
      return this.configData.CONTRACTS.find(
        (contract) => contract.ContractType?.trim() === this.formData.contractType?.trim()
      )?.Items?.filter((item) => item.LKStatus);
    },
    businessCar() {
      const contract = this.formData?.contractType;
      const level = this.formData?.contractLevel;
      const contractType = [
        "EQUANS",
        "Qwest",
        "EDF Nuclear Power Stations",
        "Irish (Private)",
        "NTC (Generic)",
        "Annualised Hours (Non-Irish)",
      ];
      const levelTypes = ["4B", "4B Sales", "5", "5A", "5B"];
      const value =
        contractType.includes(contract) &&
        new RegExp(levelTypes.join("|")).test(level);
      return value;
    },
    bonusType() {
      const contract = this.formData?.contractType;
      const level = this.formData?.contractLevel;
      const levelTypes = [1, 2, 3, 4];
      const value = new RegExp(levelTypes.join("|")).test(level);

      switch (contract) {
        case "EQUANS":
        case "Qwest":
          return value;
        default:
          return false;
      }
    },
    carAllowance() {
      const contract = this.formData?.contractType;
      const level = this.formData?.contractLevel;
      const levelTypes = [
        "1A",
        "1B",
        "1B (Board)",
        "1B (Senior Mgt)",
        "2A",
        "2B",
        "3A",
        "3A (Sales)",
        "3B",
        "3B (Sales)",
        "4A",
        "4A (Sales)",
      ];
      const levelRegex = new RegExp(levelTypes.join("|"));

      return (
        (contract === "Irish (Private)" && levelRegex.test(level)) ||
        (contract === "Irish (Healthcare & Education)" &&
          (level.includes("3B") ||
            level.includes("4A") ||
            level.includes("5A"))) ||
        (contract === "Qwest" && levelRegex.test(level)) ||
        (contract === "EQUANS" && levelRegex.test(level))
      );
    },

    contractLevels() {
      const contract = this.configData.CONTRACTS.find(
        (cont) => cont.ContractType?.trim() === this.formData.contractType?.trim()
      );
      if (contract) {
        return contract.Items;
      }
      return [];
    },
    currentContractLevel() {
      let contract = this.configData.CONTRACTS.find(
        (cont) => cont.ContractType?.trim() === this.formData.contractType?.trim() && cont.LKStatus
      );
      if (!contract) {
        contract = this.configData.CONTRACTS.find(
          (cont) => cont.ContractType?.trim() === this.formData.contractType?.trim()
        );
      }
      let level = contract?.Items.find(
        (l) => l.ContractName === this.formData.contractLevel && l.LKStatus
      );
      if (!level) {
        level = contract?.Items.find(
          (l) => l.ContractName === this.formData.contractLevel
        );
      }
      return level;
    },
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    },
    "model.additionalAllowances"(newVal) {
      if (newVal === "Yes") {
        this.model.additionalAllowancesInformation = [
          {
            additionalAllowancesTitle: "",
            additionalAllowancesInformation: "",
            additionalAllowancesCurrency: "GBP",
          },
        ];
      } else {
        this.model.additionalAllowancesInformation = [];
      }
    },
  },
  validations() {
    return {
      formData: {
        businessVehicle: {
          requiredIf: helpers.withMessage(
            "Please select a business vehicle",
            requiredIf(this.businessCar)
          ),
        },
        contractType: {
          required,
        },
        contractLevel: {
          required,
        },
        salaryBasis: {
          required,
        },
        minimumPayRate: {
          required,
        },
        minimumPayRateCurrency: {
          required,
        },
        maximumPayRate: {
          required,
        },
        maximumPayRateCurrency: {
          required,
        },
        additionalAllowances: {
          required,
        },
        payrollFrequency: {
          required,
        },
      },
    };
  },
};
</script>
